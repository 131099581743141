import React from 'react';
import { Navbar, Nav, Container } from "react-bootstrap";
import "./Header.css"

function Header() {
  return (
    <div className='header'>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand>Minsung Park</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link>
                <a variant="primary" href="/#">TEST1</a>
              </Nav.Link>
              <Nav.Link>
                <a variant="secondary" href="/#">TEST2</a>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  </div>    
  )
}

export default Header;