import React from "react";
import Typical from 'react-typical'
import "./style.css";

function Main() {
        return(
            <div className="main">
                <div className="mainsub">
                    <img src="./Main.jpg" alt="IMG NOT LOADED" width="100%"></img>
                    <p className="p1">
                        Welcome to {''}
                        <Typical
                            steps={['My Portfolio! 😀', 1000, 'My Page! 😀', 1800]}
                            loop={Infinity}
                            wrapper="b"
                        />
                    </p>
                    <p>Preparing / 준비중</p>
                </div>
            </div>
        ) 
} 

export default Main;