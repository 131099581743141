import Layout from "./layout/Layout";
// import Main from "./pages/Main";

function App() {
  return (
    <Layout></Layout>
  );
}

export default App;
